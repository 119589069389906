<template >
    <Autocomplete
        v-bind="$attrs"
        v-on="$listeners"
        :items="filterableSubContractors"
        @click="getSubContractors()"
        @load-more-items="getSubContractorsLazyLoading()"
        :loading="isSubContractorsLoading"
        :search-input.sync="searchSubContractors"
        v-model="valueProxy"
        @change="updateValue()"
        ></Autocomplete>
</template>

<script>
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import subContractorsApi from '@/utils/api-v2/subContractors';
import debounce from 'lodash.debounce';

export default {
    name: 'AutocompleteSubcontractors',
    components: { Autocomplete },
    props: {
        filter: {
            type: Object,
            'default': null
        },
        value: {
            required: true
        }
    },
    data: () => ({
        filterableSubContractors: [],
        totalSubContractorsAmount: 0,
        debouncedGetSubContractors: null,
        isSubContractorsLoading: false,
        searchSubContractors: null,
        subContractorsPaginationPage: 1,
        valueProxy: null
    }),
    created () {
        this.valueProxy = this.value;
        this.debouncedGetSubContractors = debounce(this.getSubContractors, 1000);
    },
    mounted () {
        if (this.valueProxy) {
            this.getSubContractors();
        }
    },
    watch: {
        searchSubContractors: {
            handler (val) {
                if (!val || this.isSubContractorsLoading) {
                    return;
                }

                this.debouncedGetSubContractors(val);
            }
        }
    },
    methods: {
        updateValue: function () {
            this.$emit('change', true);
            this.$emit('input', this.valueProxy);
        },
        prepareParam (withPage = false) {
            let params = {};
            if (typeof this.filter === 'object') {
                params = { ...this.filter };
            }
            if (this.searchSubContractors) {
                params.search = this.searchSubContractors;
            }
            if (withPage) {
                params['page[number]'] = this.subContractorsPaginationPage;
            }
            return params;
        },
        getSubContractors () {
            this.isSubContractorsLoading = true;
            this.totalSubContractorsAmount = 0;
            this.filterableSubContractors = [];

            return subContractorsApi.getSubContractors(this.prepareParam())
                .then(({ data }) => {
                    this.filterableSubContractors = data.results.map((el, index) => ({
                        value: el.id,
                        text: el.name
                    }));

                    if (data.count) {
                        this.totalSubContractorsAmount = data.count;
                    }
                    this.subContractorsPaginationPage = 1;
                })
                .finally(() => {
                    this.isSubContractorsLoading = false;
                });
        },
        getSubContractorsLazyLoading () {
            if (this.totalSubContractorsAmount > this.filterableSubContractors.length) {
                this.isSubContractorsLoading = true;
                this.subContractorsPaginationPage += 1;

                return subContractorsApi.getSubContractors(this.prepareParam(true))
                    .then(({ data }) => {
                        this.filterableSubContractors = this.filterableSubContractors.concat(data.results.map(el => ({
                            value: el.id,
                            text: el.name
                        })));

                        if (data.count) {
                            this.totalSubContractorsAmount = data.count;
                        }
                    }).finally(() => {
                        this.isSubContractorsLoading = false;
                    });
            }
        }
    }
};
</script>

<style scoped lang="scss">
</style>
