<template>
    <Modal :modal-title="title"
           :dialog="groupFormVisibility"
           max-width="600px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <ManageSitesGroupForm ref="form"></ManageSitesGroupForm>

            <ConfirmationDialog :dialog="showConfirmationDialog"
                                data-test="ConfirmationDialog"
                                @confirm="handleConfirmation($event)"
                                :text="$t('messages.save_changes')"
                                :action-button-title="$t('buttons.save')"></ConfirmationDialog>

            <ValidationError v-if="showError"></ValidationError>
        </template>
        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import ManageSitesGroupForm from '@/components/sitesGroups/manageSitesGroup/ManageSitesGroupForm';
import ConfirmationDialog from '@/components/widgets/dialogs/ConfirmationDialog';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManageSitesGroup',
    components: { Modal, ManageSitesGroupForm, ConfirmationDialog, ValidationError },
    data: () => ({
        showConfirmationDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapState('manageSitesGroup', [
            'groupFormVisibility',
            'editedGroupId'
        ]),
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        },
        isEditable () {
            return this.editedGroupId !== null;
        },
        title () {
            return this.isEditable
                ? this.$t('configurations.employees_groups.edit_title')
                : this.$t('configurations.employees_groups.add_title');
        }
    },
    methods: {
        close () {
            this.$store.commit('manageSitesGroup/SET_GROUP_FORM_VISIBILITY', false);
            this.$refs.form.resetValidation();
            // prevent showing bad data 0.1s
            setTimeout(() => {
                this.$store.commit('manageSitesGroup/RESET_GROUP_DATA');
                this.$store.commit('manageSitesGroup/SET_EDITED_GROUP_ID', null);
                this.showError = false;
            }, 100);
        },
        visibility (event) {
            this.$store.commit('manageSitesGroup/SET_GROUP_FORM_VISIBILITY', event);
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        createSitesGroup () {
            this.loader = true;
            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageSitesGroup/createSitesGroup')
                            .then(() => {
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        updateSitesGroup () {
            this.loader = true;

            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        return this.$store.dispatch('manageSitesGroup/updateSitesGroup')
                            .then(() => {
                                this.$emit('fetch');
                                this.close();
                            })
                            .finally(() => {
                                this.loader = false;
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.isEditable) {
                this.updateSitesGroup();
            } else {
                this.createSitesGroup();
            }
        },
        handleConfirmation (event) {
            if (event) {
                this.save();
                this.showConfirmationDialog = false;
            } else {
                this.showConfirmationDialog = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
