import debounce from 'lodash.debounce';
import { mapState, mapGetters } from 'vuex';

export default {
    data: () => ({
        debouncedGetSubContractors: null,
        isSubContractorsLoading: false,
        searchSubContractors: null,
        subContractorsPaginationPage: 1
    }),
    computed: {
        ...mapState('subContractors', [
            'totalSubContractorsAmount'
        ]),
        ...mapGetters('subContractors', [
            'filterableSubContractors'
        ])
    },
    watch: {
        searchSubContractors: {
            handler (val) {
                if (!val || this.isSubContractorsLoading) {
                    return;
                }

                this.debouncedGetSubContractors(val);
            }
        }
    },
    methods: {
        clearSubContractors () {
            this.$store.dispatch('subContractors/clearSubContractors');
        },
        getSubContractors (additionalParams = null) {
            this.isSubContractorsLoading = true;

            let params = {};

            if (typeof additionalParams === 'string') {
                params.search = additionalParams;
            } else if (typeof additionalParams === 'object') {
                params = { ...additionalParams };
            }

            return this.$store.dispatch('subContractors/getSubContractors', params)
                .then(() => {
                    this.subContractorsPaginationPage = 1;
                })
                .finally(() => {
                    this.isSubContractorsLoading = false;
                });
        },
        getSubContractorsLazyLoading (additionalParams = null) {
            if (this.totalSubContractorsAmount > this.filterableSubContractors.length) {
                this.isSubContractorsLoading = true;

                this.subContractorsPaginationPage += 1;

                let params = {};

                if (typeof additionalParams === 'object') {
                    params = { ...additionalParams };
                }

                params['page[number]'] = this.subContractorsPaginationPage;

                const _name = this.searchSubContractors;

                if (_name) {
                    params.search = _name;
                }

                return this.$store.dispatch('subContractors/getSubContractorsLazyLoading', params)
                    .finally(() => {
                        this.isSubContractorsLoading = false;
                    });
            }
        }
    },
    created () {
        this.debouncedGetSubContractors = debounce(this.getSubContractors, 1000);
    }
};
