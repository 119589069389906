<template>
    <v-sheet color="white"
             class="pt-4 pb-0 px-0"
             elevation="1">
        <ValidationObserver ref="obs"
                            tag="div"
                            class="px-4">
            <TextField v-model="name"
                       name="name"
                       ref="name"
                       rules="required"
                       :label="$t('labels.site_groups_name')"></TextField>
            <TextField v-model="code"
                       name="code"
                       ref="code"
                       rules="required"
                       :label="$t('labels.site_groups_code')"></TextField>
            <Autocomplete :label="$t('labels.site')"
                          name="locations"
                          :items="sites"
                          clearable
                          :multiple="true"
                          @click="getSites()"
                          @load-more-items="getSitesLazyLoading()"
                          :loading="isSitesLoading"
                          :search-input.sync="searchSites"
                          v-model="locations"></Autocomplete>
            <div v-for="(el,i) in subContractorsTypes" :key="i">
                <AutocompleteSubcontractors
                          :label="$t('labels.subcontractors') + ' ' + el.text"
                          :filter="{'type':el.value}"
                          name="subcontractorsGroups[el.value]"
                          clearable
                          :multiple="false"
                          v-model="subcontractorsGroups[el.value]"></AutocompleteSubcontractors>
            </div>

        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import AutocompleteSubcontractors from '@/components/widgets/autocompletes/AutocompleteSubcontractors';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedSubContactors from '@/mixins/debounced/debouncedSubContactors';
import debouncedSubContactorsTypes from '@/mixins/debounced/debouncedSubContactorsTypes';

export default {
    name: 'ManageSitesGroupForm',
    mixins: [debouncedSites, debouncedSubContactors, debouncedSubContactorsTypes],
    components: { ValidationObserver, TextField, Autocomplete, AutocompleteSubcontractors },
    computed: {
        ...mapFields('manageSitesGroup', [
            'groupData.name',
            'groupData.code',
            'groupData.locations',
            'groupData.subcontractorsGroups'
        ]),
        ...mapState('manageSitesGroup', [
            'groupFormVisibility'
        ]),
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    watch: {
        groupFormVisibility: {
            handler (val) {
                if (val) {
                    const sites_ids = this.locations.length > 0 ? this.locations : null;
                    this.getSites(null, sites_ids);
                    this.getSubContractorsTypes();
                }
            },
            immediate: true
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>

<style scoped>

</style>
